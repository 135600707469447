<template>
    <v-card>
        <v-card-title>
            Selecione o endereço
        </v-card-title>
        <v-card-text>
            <v-list-item-group>
                <v-list-item v-for="address in addressess" :key="address.id" >
                    <template>
                        <v-list-item-content @click="select(address.id)" >
                            <v-list-item-title> {{address.principal == 'T' ? 'Principal -' : ''}} {{address.endereco + ' - ' + address.numero}}</v-list-item-title>
                            <v-list-item-subtitle>{{address.complemento}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list-item-group>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="red"
                class="mx-2"
                dark

                @click="cancel()"
            >
                Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <!-- <v-btn
                color="indigo darken-2"
                class="mx-2"
                dark
            >
                Selecionar
            </v-btn> -->
        </v-card-actions>
        <v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
    </v-card>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        data(){
            return {
                overlay: false,
                addressess: [],
                addressSelected: 0
            }
        },

        methods: {
            ...mapActions({
                address: 'ENDERECOS'
            }),

            getData: function(){
                this.overlay = true
                this.address()
                    .then(res => (res.json()))
                    .then(resJSON => {
                        if (resJSON.data) {
                            if(resJSON.response.length > 0){
                                this.addressess = resJSON.response
                            }else{
                                console.log('Error', '=>', 'Nenhum endereço encontrado')
                            }
                        }else{
                            console.log('Error', '=>', resJSON.message)
                        }
                    })
                    .catch(error => {
                        console.error('Exception', '=>', error)
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            },

            cancel: function(){
                this.$emit('close')
            },

            select: function(id){
                this.$emit('select', id)
            }
        },

        mounted(){
            this.getData()
        }
    }
</script>