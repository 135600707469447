import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VuePageTransition from 'vue-page-transition'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueMask from 'v-mask'

const moment = require('moment')
require('moment/locale/pt-br')

moment.locale('pt-br');

Vue.use(VueMask)
Vue.use(VuePageTransition)
Vue.use(require('vue-moment'), {
    moment,
})

Vue.use(VueLodash, {
    name: 'custom',
    lodash: lodash
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: function(h) { return h(App) }
}).$mount('#app')

//To-do
/**
 * Menu lateral com opções de contato e jointech
 * Criar as regras para os inputs na tela de login com telefone
 * Criar alerta de erros caso ocorram nas requisições
 */