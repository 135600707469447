import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const api = process.env.VUE_APP_API

export default new Vuex.Store({
    state: {
        logado: false,
        empresa: 0,
        carrinho: [],
        user: {
            id: 0,
            name: '',
            phone: '',
            email: ''
        },
        selectedAddress: 0
    },
    mutations: {
        /**
         * Metodo responsavel por gravar os dados de autenticação
         * 
         * @param {Object} state
         * @param {Object} data
         */
        LOGIN(state, data) {
            state.logado = true
            state.user = {
                id: data.id,
                name: data.name,
                phone: data.phone,
                email: data.mail
            }

            if (data.stayLogged) {
                // Grava os dados do usuario no localstorage
                window.localStorage.setItem('ges-info', JSON.stringify({
                    id: data.id,
                    name: data.name,
                    phone: data.phone,
                    stayLogged: data.stayLogged
                }))
            }

            return Promise.resolve()
        },

        LOGOUT(state) {
            state.logado = false
            state.empresa = ''
            state.carrinho = []
            state.user = {
                id: 0,
                name: '',
                phone: '',
                email: ''
            }

            state.selectedAddress = 0
        }
    },
    actions: {
        LOGIN: function(context, data) {
            return fetch(api + 'auth', {
                method: 'POST',
                headers: new Headers({
                    Authenticate: JSON.stringify({
                        user: data.user.replace(/[^0-9]/g, ''),
                        pass: btoa(data.pass)
                    })
                })
            })
        },

        LOGOUT: function(context) {
            window.localStorage.removeItem('ges-info')

            context.commit('LOGOUT')
        },

        TOKEN: function(context) {
            context.state.empresa = document.querySelector('meta[name="jt-token"]').content
        },

        EMPRESA: function(context) {
            return fetch(api + 'empresa', {
                method: 'GET',
                headers: new Headers({
                    authorization: context.state.empresa
                })
            })
        },

        CARDAPIO: function(context, id) {
            let url = api + 'cardapio' + (id === null ? '' : '/' + id)
            return fetch(url, {
                method: 'GET',
                headers: new Headers({
                    authorization: context.state.empresa
                })
            })
        },

        ADICIONAIS: function(context, id) {
            return fetch(api + 'cardapio/adicionais/' + id, {
                method: 'GET',
                headers: new Headers({
                    authorization: context.state.empresa
                })
            })
        },

        ENDERECOS: function(context) {
            return fetch(api + 'clientes/' + context.state.user.id + '/enderecos', {
                method: 'GET',
                headers: new Headers({
                    authorization: context.state.empresa
                })
            })
        },

        ADICIONAR_ITEM: function(context, item) {
            context.state.carrinho.push(item)
        },

        LIMPAR_CESTA: function(context) {
            context.state.carrinho = []
        },

        RETIRAR_DA_CESTA: function(context, id) {
            context.state.carrinho.splice(id, 1)
        },

        /**
         * Função para cadastrar um novo cliente - usuário.
         * @param {Any} context
         * @param {Object} data
         */
        NOVO_CLIENTE: function(context, data) {
            return fetch(api + 'clientes/novo', {
                method: 'POST',
                headers: new Headers({
                    "data": JSON.stringify({
                        "nome": btoa(data.nome),
                        "cpf": btoa(data.cpf.replace(/[^0-9]/g, '')),
                        "telefone": btoa(data.telefone.replace(/[^0-9]/g, '')),
                        "empresa": context.state.empresa,
                        "senha": btoa(data.senha)
                    })
                })
            })
        },

        /**
         * Função para cadastrar um novo cliente - usuário.
         * @param {Any} context
         * @param {Object} data
         */
        NOVO_ENDERECO: function(context, data) {
            return fetch(api + 'clientes/enderecos/novo', {
                method: 'POST',
                headers: new Headers({
                    "data": JSON.stringify({
                        "cep": data.cep.replace(/[^0-9]/g, ''),
                        "rua": data.rua,
                        "numero": data.numero.replace(/[^0-9]/g, ''),
                        "bairro": data.bairro,
                        "cidade": data.cidade,
                        "uf": data.uf,
                        "complemento": data.complemento,
                        "referencia": data.referencia,
                        "principal": data.principal,
                        "pessoa": data.user_id
                    })
                })
            })
        },

        NOVO_PEDIDO: function(context, data) {
            return fetch(api + 'pedidos/novo', {
                method: 'POST',
                headers: new Headers({
                    "data": JSON.stringify({
                        "cliente": context.state.user.id,
                        "valor": data.valor,
                        "empresa": context.state.empresa,
                        "entrega": data.entrega,
                        "endereco": data.endereco,
                        "itens": JSON.stringify(context.state.carrinho)
                    })
                })
            })
        },

        HISTORICO: function(context) {
            return fetch(api + 'clientes/' + context.state.user.id + '/pedidos', {
                method: 'GET',
                headers: new Headers({
                    authorization: context.state.empresa
                })
            })
        },

        HISTORICO_PEDIDO: function(context, id) {
            return fetch(api + 'clientes/' + context.state.user.id + '/pedidos/' + id, {
                method: 'GET',
                headers: new Headers({
                    authorization: context.state.empresa
                })
            })
        }
    },
    modules: {},
    getters: {
        logado: state => {
            return state.logado
        },

        carrinho: state => {
            return state.carrinho
        }
    }
})