<template>
	<v-app>
		<v-app-bar
			app
			color="indigo"
			elevate-on-scroll
		>
			<v-btn
				outlined
				small
				fab
				color="white"
				:to="{name: 'Home'}"
			>
				<!-- <v-icon>mdi-home</v-icon> -->
				<!-- <span class="mr-2">Ínicio</span> -->
				<i class="fas fa-home "></i>
			</v-btn>

			<v-spacer></v-spacer>

			<v-btn
				outlined
				small
				fab
				color="white"

				v-if="logado"
			>
				<!-- <span class="mr-2">Menu</span> -->
				<v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white"></v-app-bar-nav-icon>
			</v-btn>
		</v-app-bar>

		<v-navigation-drawer
			v-model="drawer"
			fixed
			temporary
			right

			v-if="logado"
		>
			<v-list
				nav
				dense
			>
				<v-list-item-group
					v-model="group"
					active-class="deep-purple--text text--accent-4"
				>
					<v-list-item :to="{name: 'Home'}">
						<!-- <v-icon>mdi-home</v-icon> -->
						<i class="fas fa-home mr-2"></i>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item>

					<v-list-item :to="{name: 'Historico'}" >
						<!-- <v-icon>mdi-wrench</v-icon> -->
						<i class="fas fa-history mr-2"></i>
						<v-list-item-title>Histórico</v-list-item-title>
					</v-list-item>

					<v-list-item @click="logout()">
						<!-- <v-icon left>mdi-arrow-left</v-icon> -->
						<i class="fas fa-sign-out-alt mr-2"></i>
						<v-list-item-title>Sair</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<v-card
			class="rounded-0"
		>
			<v-img
				height="60vh"
				:src="empresa.background"
			>
				<v-card-title class="white--text mt-8 justify-center pt-8">
					<v-row
						class="pt-8"
					>
						<v-col
							cols="12"
							class="justify-center text-center"
						>
							<v-avatar size="112" class="m-auto">
								<img
									alt="user"
									:src="empresa.logo"
								>
							</v-avatar>
						</v-col>
						<v-col
							cols="12"
							class="justify-center text-center"
						>
							<h1 class="">
								{{empresa.nome}}
							</h1>
							<v-card-subtitle
								class="white--text"
							>
								<h3> Aberto agora </h3>
							</v-card-subtitle>
						</v-col>
					</v-row>
				</v-card-title>
			</v-img>
		</v-card>

		<v-main class="pt-4 px-2 mb-4" >
			<vue-page-transition name="fade-in-left">
				<v-row>
					<v-col 
						cols="12"
						:md="logado == true ? '8' : '12'"
					>
						<vue-page-transition name="fade-in-left">
							<router-view/>
						</vue-page-transition>
					</v-col>
					<v-col 
						md="4"
						cols="12"

						v-if="logado"
					>
						<jt-carrinho />
					</v-col>
				</v-row>
			</vue-page-transition>
		</v-main>

		<v-app-bar
			bottom
			color="indigo"
			class="white--text"
		>
			<h4 style="margin: 0 auto" >Jointech Tecnologia em Sistemas</h4>
		</v-app-bar>
		<v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
	</v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'


import jtCarrinho from './components/jtCarrinho';

export default {
	name: 'App',

	components: {
		jtCarrinho,
	},

	data(){
		return {
			empresa: {},
			overlay: false,
			drawer: false,
			group: null
		}
	},

	computed: {
		...mapGetters({
			logado: 'logado'
		})
	},
	
	watch: {
      group () {
        this.drawer = false
      }
    },

	methods: {
		...mapMutations({
			doLogin: 'LOGIN',
		}),

		...mapActions({
			token: 'TOKEN',
			getEmpresa: 'EMPRESA',
			doLogout: 'LOGOUT'
		}),

		buscarEmpresa: function(){
				this.overlay = true
				this.getEmpresa()
					.then(response => ( response.json() ) )
					.then(resJSON => {
						this.empresa = resJSON.response[0]
					})
					.catch(error => {
						console.error('Error', '=>', error)
					})
					.finally(() => {
						this.overlay = false
					})
		},
		logout: function() {
			this.doLogout()
			this.$router.push({
				name: 'Login'
			})
		},

		hasInfo: function(){
			let item = JSON.parse(window.localStorage.getItem('ges-info'))
			if (typeof item == 'object' && item !== null && item !== undefined) {

				this.doLogin({
					id: item.id,
					name: item.name,
					phone: item.phone,
					email: item.mail
				})

				this.$router.push({
					name: 'Home'
				})
			}
		}
	},

	mounted(){
		this.token()
		this.buscarEmpresa()
		this.hasInfo()
	},

	created(){
		// this.hasInfo()
	}
};
</script>

<style scoped>
	/* .v-app-bar--is-scrolled{
		background: #FFF !important;
	} */
</style>
