<template>
	<v-card>
		<v-card-title
			class="indigo white--text"
		>
			<h3>Carrinho</h3>
			<v-spacer></v-spacer>
			Total R$ {{total}}
		</v-card-title>

		<v-card-text 
			v-if="produtos.length === 0" 
			class="pt-8 text-center"
		>
			<div class="text-center" style="text-align: center">
				<h2>Seu carrinho esta vazio</h2>
				<p>Parece que ainda não pediu nada</p>
			</div>
		</v-card-text>

		<v-card-text v-else >
			<v-list
				
			>
				<template
					v-for="(p, id) in produtos"
				>
					<v-list-item 
						:key="'item-' + id" 
						class="p-0"
					>
						<v-list-item-content>
							<v-list-item-title v-text="p.quantidade + 'x - ' + p.descricao" ></v-list-item-title>
							<v-list-item-subtitle class="px-2" >
								Valor Total <strong>R$ {{p.valor_total}}</strong>
								<v-spacer></v-spacer>
								<strong>Adicionais: </strong> 
								<v-spacer></v-spacer>
								<ul>
									<li
										v-for="(ad, id) in p.adicionais" 
										:key="'adicionais-' + id"
									>
										{{ad.descricao}}
										<v-spacer></v-spacer>
										R$ {{ad.valor}}
									</li>
								</ul>
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn
								class="mx-2"
								fab
								dark
								small
								color="red"
								@click.prevent="retirarDaCesta(id)"
							>
								<v-icon dark>
									mdi-minus
								</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>

					<v-divider
						:key="'divider-' + id"
					></v-divider>
				</template>
			</v-list>
		</v-card-text>

		<v-card-actions 
			class="pb-8"
			v-if="produtos.length !== 0"
		>
			<v-row>
				<v-col
					cols="6"
				>
					<v-btn
						dark
						color="red"
						block
						@click.prevent="limparCesta()"
					>
						Limpar
					</v-btn>
					<!-- <v-btn
						dark
						color="indigo"
						block
						@click.prevent="finalizar()"
					>
						Finalizar
					</v-btn> -->
				</v-col>
				<v-col
					cols="6"
				>
					<v-dialog
						v-model="dialog"
						width="500"
					>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							dark
							color="indigo"
							block
							v-bind="attrs"
							v-on="on"
						>
							Finalizar
						</v-btn>
					</template>
						<jt-address-modal
							v-on:close="dialog = false"
							v-on:select="finalizar"
						></jt-address-modal>
					</v-dialog>
				</v-col>
			</v-row>
		</v-card-actions>
		<v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>

		<v-dialog
			v-model="popup.show"
			width="300"
		>
			<v-card
			>
				<v-card-title>
					Desculpe ocorreu um erro.
				</v-card-title>
				<v-card-text>
					{{popup.text}}
				</v-card-text>
				<v-card-actions>
					<v-button
						color="red"
						dark
						@click="popup.show = false"
					>
						Fechar
					</v-button>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

import jtAddressModal from './jt-address-modal'
export default {
	name: "jtCarrinho",
	components: {
		jtAddressModal
	},
	data() {
		return {
			dialog: false,
			overlay: false,
			popup: {
				show: false,
				text: ''
			}
		}
	},
	computed: {
		produtos(){
			return this.$store.getters.carrinho
		},

		total(){
			let valor = 0
			this.produtos.forEach(element => {
				valor = parseFloat(valor) + parseFloat(element.valor_total)
			})

			return valor
		}
	},
	methods: {
		...mapActions({
			buy: 'NOVO_PEDIDO',
			clear: 'LIMPAR_CESTA',
			remove: 'RETIRAR_DA_CESTA'
		}),

		finalizar: function(id){
			this.dialog = false
			this.overlay = true
			let totalCompra = this.total.toString()

			this.buy({
				"valor": totalCompra,
				"entrega": 'S',
				"endereco": id
			})
				.then(res => (res.json()))
				.then(resJSON => {
					console.log(resJSON)
					if(resJSON.data){
						this.clear()
						// Mostra ao usuário o pedido
						this.$router.push({
							name: 'Historico_detalhe',
							params: {
								id: resJSON.response
							}
						})
					}else{
						console.log('Error')
						// Popup de erro
						this.popup = {
							show: true,
							text: resJSON.message
						}
					}
				})
				.catch(error => {
					console.error('Exception', '=>', error)
					// popup de erro
						this.popup = {
							show: true,
							text: 'Erro ao se conectar no servidor.'
						}
				})
				.finally(() => {
					this.overlay = false
				})
		},
		
		retirarDaCesta: function(id){
			this.remove(id)
		},

		limparCesta: function(){
			this.clear()
		}
	}
};
</script>
