import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Store from '../store/index'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/inicio',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/produto',
        name: 'Produto',
        component: () =>
            import ('../views/Produto.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/cliente',
        name: 'Cliente',
        component: () =>
            import ('../views/Cliente.vue'),
        meta: {}
    },
    {
        path: '/historico',
        name: 'Historico',
        component: () =>
            import ('../views/history/index.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/historico/:id',
        name: 'Historico_detalhe',
        component: () =>
            import ('../views/history/view.vue'),
        meta: {
            auth: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (Store.getters.logado) {
            next()
            return
        } else {
            next('/')
        }
    } else {
        next()
    }
})