<template>
    <v-card>
        <v-card-title
            class="indigo white--text"
        >
            <h3>Login</h3>
            <v-spacer></v-spacer>
            Gestor Food
        </v-card-title>
        <v-card-text class="pt-4" >
            <v-form>

                <v-alert
                    v-model="alert.show"
                    dismissible
                    color="light-blue dark-1"
                    border="left"
                    elevation="2"
                    colored-border
                    transition="scale-transition"
                >
                    {{alert.text}}
                </v-alert>

                <v-text-field
                    v-mask="maskTel()"
                    label="Telefone" 
                    prepend-inner-icon="mdi-phone"
                    counter
                    maxlength="15"
                    v-model="form.telefone"
                    :rules="[
                        form_telefone_rules.required, form_telefone_rules.counter,
                            form_telefone_rules.min
                    ]"
                ></v-text-field>
                <!-- <small v-if="form_telefone_rules.required.invalid" >Esse campo é requerido</small> -->

                <v-text-field
                    label="Senha"
                    prepend-inner-icon="mdi-key"
                    type="password"
                    hint="Pelo menos 8 caracteres"
                    v-model="form.senha"
                    :rules="[
                        form_login_rules.required, form_login_rules.min
                    ]"
                ></v-text-field>

                <v-checkbox
                    color="indigo"
                    :value="true"
                    v-model="form.stayLogged"
                    label="Manter logado"
                ></v-checkbox>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="indigo"
                dark
                block

                @click.prevent="login"
            >
                Login
            </v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-btn
                :to="{name: 'Cliente'}"
                color="indigo"
                dark
                block
            >
                Cadastrar
            </v-btn>
        </v-card-actions>

        <v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
    </v-card>
</template>
<script>
    import { mapActions, mapMutations } from 'vuex'
    export default {
        name: 'vwLogin',
        data() {
            return {
                overlay: false,
                form: {
                    telefone: '',
                    senha: '',
                    stayLogged: false
                },
				alert: {
					show: false,
					text: "",
				},


                form_telefone_rules: {
                    required: value => !!value || 'Telefone é obrigatório.',
                    counter: value => value.length <= 15 || 'Telefone inválido!',
                    min: v => v.length >= 14 || 'Telefone inválido'
                },
                form_login_rules: {
                    required: value => !!value || 'Senha é obrigatória.',
                    min: v => v.length >= 8 || 'Mínimo de 8 caracteres'
                }
            }
        },

        methods: {
            ...mapActions({
                doLogin: 'LOGIN'
            }),

            ...mapMutations({
                saveLogin: 'LOGIN'
            }),

            maskTel: function() {
                return this.form.telefone.length == 15 ? '(##) #####-####' : '(##) ####-####'
            },

            login: function(){
                this.overlay = true
                this.doLogin({
                    user: this.form.telefone,
                    pass: this.form.senha,
                })
                    .then(res => (res.json()))
                    .then(resJSON => {
                        if (resJSON.data) {
                            resJSON.response.stayLogged = this.form.stayLogged

                            this.saveLogin(resJSON.response)
                            // this.$store.commit('LOGIN', resJSON.response)
                                // .then(() => {
                                    this.$router.push({
                                        name: 'Home'
                                    })
                                // })
                        }else{
                            //alert
                            this.alert = {
                                show: true,
                                message: resJSON.message
                            }                            
                        }
                    })
                    .catch(() => {
                        this.alert = {
                            show: true,
                            message: 'Problemas internos. Tente novamente.'
                        }
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            }
        },

        computed: {

        }
    }
</script>
